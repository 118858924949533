
import { Options, Vue } from "vue-property-decorator";
import * as api from "@/api/videoInspection";
@Options({})
export default class extends Vue {
  private allInspection = [
    { text: "全部巡检", value: "" },
    { text: "安全巡检", value: 1 },
    { text: "质量巡检", value: 2 },
  ];
  private allQuestion = [
    { text: "全部问题", value: "" },
    { text: "我创建的", value: 2 },
    { text: "我处理的", value: 3 },
  ];
  private allStatus = [
    { text: "全部状态", value: "" },
    { text: "未处理", value: 1 },
    { text: "已处理", value: 2 },
    { text: "已解决", value: 3 },
  ];
  private checkType = "";
  private screenType = "";
  private status = "";
  private show = false;
  refreshing = false;
  loading = false;
  finished = false;

  safeList: any[] = [];
  page = {
    pageSize: 10,
    pageNum: 1,
  };

  statusImg(val: any) {
    if (val === 1) {
      return require(`@/assets/videoInspection/normal.png`);
    }
    if (val === 2) {
      return require(`@/assets/videoInspection/abnormal.png`);
    }
    if (val === 0) {
      return require("@/assets/videoInspection/inspect.png");
    }
  }

  fGoDetail(item: any) {
    if (item.result == 0) {
      this.$router.push({
        path: "/app/videoInspectionEnd",
        query: { id: item.checkId },
      });
    } else {
      this.$router.push({
        path: "/app/videoInspectionInfo",
        query: {
          id: item.checkId,
        },
      });
    }
  }
  fGoAdd() {
    this.$router.push("/app/videoInspectionAdd");
  }

  fChangeSearch() {
    this.refreshing = true;
    this.onRefresh();
  }
  onLoad() {
    setTimeout(() => {
      if (this.refreshing) {
        this.safeList = [];
        this.page.pageNum = 1;
        this.refreshing = false;
      }
      const reqjson = {
        projectId: this.$store.state.project.projectinfo.projectId,
        checkType: this.checkType,
        screenType: this.screenType,
        status: this.status,
        ...this.page,
      };

      api.pageAll(reqjson).then((res: any) => {
        this.safeList = [...this.safeList, ...res.data.records];
        this.page.pageNum++;
        this.loading = false;
        if (this.safeList.length >= res.data.total) {
          this.finished = true;
        }
      });
    }, 1000);
  }

  onRefresh() {
    // 清空列表数据
    this.finished = false;
    // 重新加载数据
    // 将 loading 设置为 true，表示处于加载状态
    this.loading = true;
    this.onLoad();
  }
}
